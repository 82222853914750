import Vue from "vue"
import store from "../index.js"
import { setInfo, getInfo, getInfo2, delInfo } from "@/utils/session.js"
import { login } from "@/api/login"
import { getTree } from "@/api/projectDailyEneryTableManage"
const state = {
  useState: getInfo("useState"),
  name: getInfo("name"),
  avatar: getInfo("avatar"),
  userId: getInfo("userId"),
  orgId: getInfo("orgId"),
  title: getInfo("title"),
  password: getInfo("password"),
  menu: getInfo("menu", 0),
  orgList: getInfo("orgList", 0),
  fhkzType: getInfo("fhkzType"),
  btnPermission: getInfo("btnPermission"),
  token: getInfo("token"),
  // 管网/热网数据点
  bipRealData: getInfo("bipRealData"),
  // 分户/户端数据点
  houseRealData: getInfo("houseRealData"),
  // 数据查询数据点
  queryRealData: getInfo("queryRealData"),
  // 数据查询加入对比数据点
  compareDataPoints: getInfo("compareDataPoints"),
  // 室温历史数据点
  historyRealData: getInfo("historyRealData"),
  // 气象历史数据点
  wetherRealData: getInfo("wetherRealData"),
  // 运行记录
  operationData: getInfo("operationData"),
  // 用户信息
  userInfo: getInfo("userInfo", 0),
  // 供暖季
  heatSeason: getInfo("heatSeason", 0)
}
const mutations = {
  SET_PASSWORD: (state, password) => {
    setInfo("password", password)
    state.password = password
  },
  SET_TOKEN: (state, token) => {
    setInfo("token", token)
    state.token = token
  },
  SET_USERSTETE: (state, useState) => {
    setInfo("useState", useState)
    state.useState = useState
  },
  SET_NAME: (state, name) => {
    setInfo("name", name)
    state.name = name
  },
  SET_AVATER: (state, avatar) => {
    setInfo("avatar", avatar)
    state.avatar = avatar
  },
  SET_USERID: (state, userId) => {
    setInfo("userId", userId)
    state.userId = userId
  },
  SET_ORGID: (state, orgId) => {
    setInfo("orgId", orgId)
    state.orgId = orgId
  },
  SET_TITLE: (state, title) => {
    setInfo("title", title)
    state.title = title
  },
  SET_MENU: (state, menu) => {
    setInfo("menu", menu)
    state.menu = menu
  },
  SET_ORGLIST: (state, orgList) => {
    setInfo("orgList", JSON.stringify(orgList))
    state.orgList = orgList
  },
  SET_FHKZTYPE: (state, fhkzType) => {
    setInfo("fhkzType", fhkzType)
    state.fhkzType = fhkzType
  },
  SET_BTNPERMISSION: (state, btnPermission) => {
    setInfo("btnPermission", btnPermission)
    state.btnPermission = btnPermission
  },
  // -----
  SET_BIPDATAID: (state, bipRealData) => {
    setInfo("bipRealData", bipRealData)
    state.bipRealData = bipRealData
  },
  SET_HOUSEDATAID: (state, houseRealData) => {
    setInfo("houseRealData", houseRealData)
    state.houseRealData = houseRealData
  },
  SET_QUERYDATAID: (state, queryRealData) => {
    setInfo("queryRealData", queryRealData)
    state.queryRealData = queryRealData
  },
  SET_COMPAREDATAPOINTS: (state, compareDataPoints) => {
    setInfo("compareDataPoints", compareDataPoints)
    state.compareDataPoints = compareDataPoints
  },
  SET_HISTORYREALDATA: (state, historyRealData) => {
    setInfo("historyRealData", historyRealData)
    state.historyRealData = historyRealData
  },
  SET_WETHERREALDATA: (state, wetherRealData) => {
    setInfo("wetherRealData", wetherRealData)
    state.wetherRealData = wetherRealData
  },
  SET_OPERATIONDATA: (state, operationData) => {
    setInfo("operationData", operationData)
    state.operationData = operationData
  },
  SET_USERINFO: (state, userInfo) => {
    setInfo("userInfo", userInfo)
    state.userInfo = userInfo
  },
  SET_HEATSEASON: (state, heatSeason) => {
    setInfo("heatSeason", heatSeason)
    state.heatSeason = heatSeason
  }
}

const actions = {
  login({ commit }, {}) {
    return new Promise((resolve, reject) => {
      login({
        account: userName,
        password: password
      })
        .then((res) => {
          if (res.code == 200) {
            const { name, id, department, headAddress, authoritys } = res.data
            // if (authoritys.length > 0) {
            //     authoritys.map(item => {
            //         item["AUTHORITY"] = item["AUTHORITY"].split(',')
            //         if (item["AUTHORITY"].length > 0) {
            //             var child = item["AUTHORITY"]
            //             child.map(c => {
            //                 auths.push(c)
            //             })
            //         }
            //     })
            // }

            commit("SET_USERSTETE", true)
            commit("SET_NAME", name)
            commit("SET_USERID", id)
            commit("SET_AVATER", headAddress)
            commit("SET_TOKEN", res.data.token)
            commit("SET_FHKZTYPE", name)
          }
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      sessionStorage.clear()
      window.name = ""
      resolve()
    })
  },
  async orgList({ commit }) {
    await getTree({}).then((res) => {
      commit("SET_ORGLIST", res.data)
    })
  },
  token({ commit }, token) {
    commit("SET_TOKEN", token)
  },
  password({ commit }, password) {
    commit("SET_PASSWORD", password)
  },
  btnPermission({ commit }, btnPermission) {
    commit("SET_BTNPERMISSION", btnPermission)
  },
  heatSeason({ commit }, heatSeason) {
    commit("SET_HEATSEASON", heatSeason)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
