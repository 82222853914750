import Vue from "vue"
import store from "../index.js"
import { setInfo, getInfo, delInfo } from "@/utils/session.js"
const state = {
  queryTime: getInfo("queryTime"),
  operaTime: getInfo("operaTime"),
  energyTime: getInfo("energyTime"),
  roomHistory: getInfo("roomHistory"),
  wetherHistory: getInfo("wetherHistory"),
  wetherGroupByTime: getInfo("wetherGroupByTime"),
  userGroupByTime: getInfo("userGroupByTime"),
  queryGroupByTime: getInfo("queryGroupByTime"),
  libraryName: getInfo("libraryName")
}

const mutations = {
  SET_QUERYTIME: (state, queryTime) => {
    setInfo("queryTime", queryTime)
    state.queryTime = queryTime
  },
  SET_OPERARYTIME: (state, operaTime) => {
    setInfo("operaTime", operaTime)
    state.operaTime = operaTime
  },
  SET_ENERGYRYTIME: (state, energyTime) => {
    setInfo("energyTime", energyTime)
    state.energyTime = energyTime
  },
  SET_WETHERHISTORY: (state, wetherHistory) => {
    setInfo("wetherHistory", wetherHistory)
    state.wetherHistory = wetherHistory
  },
  SET_WETHERGROUPBYTIME: (state, wetherGroupByTime) => {
    setInfo("wetherGroupByTime", wetherGroupByTime)
    state.wetherGroupByTime = wetherGroupByTime
  },
  SET_ROOMHISTORY: (state, roomHistory) => {
    setInfo("roomHistory", roomHistory)
    state.roomHistory = roomHistory
  },
  SET_USERGROUPBYTIME: (state, userGroupByTime) => {
    setInfo("userGroupByTime", userGroupByTime)
    state.userGroupByTime = userGroupByTime
  },
  SET_DATAQUERYGROUPBYTIME: (state, queryGroupByTime) => {
    setInfo("queryGroupByTime", queryGroupByTime)
    state.queryGroupByTime = queryGroupByTime
  },
  SET_LIBRARYNAME: (state, libraryName) => {
    setInfo("libraryName", libraryName)
    state.libraryName = libraryName
  }
}

const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
