import CryptoJS from "crypto-js"
// 随机生成指定数量的32进制key
export default {
  generatekey(num) {
    const library =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let key = ""
    for (let i = 0; i < num; i++) {
      const randomPoz = Math.floor(Math.random() * library.length)
      key += library.substring(randomPoz, randomPoz + 1)
    }
    return key
  },
  // 加密
  encrypt(word, keyStr) {
    // 判断是否存在keyStr，不存在就用默认的keyStr（注意：这个keyStr必需要前后端统一，不然双方加密解密后会不相同。调用generatekey方法生成）
    keyStr = keyStr || "1grLx91U40VawzhRAm7E"
    const key = CryptoJS.enc.Utf8.parse(keyStr)
    const srcs = CryptoJS.enc.Utf8.parse(word)
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
  },
  // 解密
  decrypt(word, keyStr) {
    keyStr = keyStr || "1grLx91U40VawzhRAm7E"
    const key = CryptoJS.enc.Utf8.parse(keyStr)
    const decrypt = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  }
}

/**
 * // 注意：如果是对象/数组的话，需要先转换成字符串
    //调用加密方法
    let cars = '离谱到家158855515485451'
    let encrypts = this.$aes.encrypt(cars);

    //调用解密方法
    let dess = this.$aes.decrypt("P2Cq833yWdH8se2rHTbxlx6pFIQpdJ9515hNIIi5rlU=");

    console.log("加密",encrypts);
    console.log("解密",dess);

 */
