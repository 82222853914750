const getters = {
  token: (state) => state.user.token,
  password: (state) => state.user.password,
  useState: (state) => state.user.useState,
  btnPermission: (state) => state.user.btnPermission,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  menu: (state) => state.user.menu,
  userId: (state) => state.user.userId,
  title: (state) => state.user.title,
  orgId: (state) => state.user.orgId,
  orgList: (state) => state.user.orgList,
  fhkzType: (state) => state.user.fhkzType,
  isHigh: (state) => state.app.isHigh,
  projectId: (state) => state.app.projectId,
  homeID: (state) => state.app.homeID,
  keyArr: (state) => state.app.keyArr,
  bipRealData: (state) => state.user.bipRealData,
  houseRealData: (state) => state.user.houseRealData,
  queryRealData: (state) => state.user.queryRealData,
  compareDataPoints: (state) => state.user.compareDataPoints,
  operationData: (state) => state.user.operationData,
  queryTime: (state) => state.time.queryTime,
  operaTime: (state) => state.time.operaTime,
  energyTime: (state) => state.time.energyTime,
  roomHistory: (state) => state.time.roomHistory,
  historyRealData: (state) => state.user.historyRealData,
  wetherHistory: (state) => state.time.wetherHistory,
  wetherRealData: (state) => state.user.wetherRealData,
  wetherGroupByTime: (state) => state.time.wetherGroupByTime,
  userGroupByTime: (state) => state.time.userGroupByTime,
  queryGroupByTime: (state) => state.time.queryGroupByTime,
  libraryName: (state) => state.time.libraryName,
  userInfo: (state) => state.user.userInfo,
  heatSeason: (state) => state.user.heatSeason
}
export default getters
