import AES from "@/utils/AES.js"
const isAes = process.env.NODE_ENV != "development"
// let isAes = true;
export function setInfo(KEY, info) {
  if (typeof info != "string") {
    const JsonStr = JSON.stringify(info)
    isAes
      ? window.sessionStorage.setItem(AES.encrypt(KEY), AES.encrypt(JsonStr))
      : window.sessionStorage.setItem(KEY, JsonStr)
  } else {
    isAes
      ? window.sessionStorage.setItem(AES.encrypt(KEY), AES.encrypt(info))
      : window.sessionStorage.setItem(KEY, info)
  }
}
export function getInfo(KEY, type) {
  var jsonStr = isAes
    ? window.sessionStorage.getItem(AES.decrypt(KEY)) || null
    : window.sessionStorage.getItem(KEY) || null
  if (jsonStr && jsonStr != "undefined") {
    if (type == 0) {
      return JSON.parse(jsonStr)
    } else {
      return jsonStr
    }
  }
}
// 删除指定key
export function delInfo(KEY) {
  isAes
    ? window.sessionStorage.removeItem(AES.decrypt(KEY))
    : window.sessionStorage.removeItem(KEY)
}
// 除了某个key全部删除
export function clearRemoveKey(noDelKey, noDelKey2) {
  for (const i in window.sessionStorage) {
    if (
      i != noDelKey &&
      i != noDelKey2 &&
      i != "length" &&
      i != "clear" &&
      i != "getItem" &&
      i != "key" &&
      i != "removeItem" &&
      i != "setItem"
    ) {
      isAes
        ? window.sessionStorage.removeItem(AES.decrypt(i))
        : window.sessionStorage.removeItem(i)
    }
  }
}
