import request from "@/utils/request.js"
/**
 *
 * @param {登录} params
 */
export function login(params) {
  return request({
    url: "/systemanage/user/login",
    // url: "/user/login",
    method: "post",
    params
  })
}
/**
 *
 * @param {获取菜单} params
 */
export function getMenu(params) {
  return request({
    url: "/systemanage/systemMenu/getTree",
    method: "post",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
/**
 *
 * @param {获取机构树} params
 */
export function getorgList(params) {
  return request({
    url: "/systemanage/systemDepartment/getTree",
    method: "post",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
/**
 *
 * @param {获取锅炉房树} params
 */
export function getBoilerTree(params) {
  return request({
    url: "/system/department/listLeftTree",
    method: "post",
    params
  })
}

/**
 *
 * @param {获取工艺树} params
 */
export function getDeciveTree(params) {
  return request({
    url: "/system/listDeviceParentId",
    method: "post",
    params
  })
}

/**
 *
 * @param {*判断是否弹窗} params
 * @returns
 */
export function messageList(params) {
  return request({
    url: "/systemanage/message/messageList",
    method: "post",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}

/**
 *
 * @param {*状态修改} params
 * @returns
 */
export function stateUpdate(params) {
  return request({
    url: "/systemanage/message/stateUpdate",
    method: "post",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
