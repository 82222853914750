import Cookies from "js-cookie"

const TokenKey = "vue_admin_template_token"

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function sessionSet(name, data) {
  sessionStorage.removeItem(name)
  sessionStorage.setItem(name, data)
}

export function sessionGet(name) {
  return sessionStorage.getItem(name)
}
export function sessionRemove(name) {
  sessionStorage.removeItem(name)
}

export function locationSet(name, data) {
  localStorage.removeItem(name)
  localStorage.setItem(name, data)
}

export function locationGet(name) {
  return localStorage.getItem(name)
}

export function locationRemove(name) {
  localStorage.removeItem(name)
}
export function sessionSetObj(name, data) {
  sessionStorage.removeItem(name)
  // sessionStorage.setItem(name, JSON.stringify(data))
  sessionStorage.setItem(name, data)
}

export function sessionGetObj(name) {
  // return JSON.parse(sessionStorage.getItem(name))
  return sessionStorage.getItem(name)
}
