import "@babel/polyfill"

import Vue from "vue"
import Router from "vue-router"
import App from "./App.vue"
import router from "./router"
import store from "./store"
// import ElementUI from "element-ui"
import locale from "element-ui/lib/locale/lang/zh-CN"
import "element-ui/lib/theme-chalk/index.css"
import "@/css/index.scss"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
// import './utils/rem'
import "lib-flexible-computer"
// 引入自定义指令
import "@/directive/el-select-loadmore/index"
import "@/directive/persstion-btn/index"
import "@/directive/clickoutside/index"
import "@/directive/el-table/index.js"
import "@/directive/drag/index.js"
// 自定义弹窗拖拽指令
import "@/directive/el-drag-dialog/index.js"
// 引入svg组件
import IconSvg from "./components/SvgIcon"
import _ from "lodash"
import VueDraggableResizable from "vue-draggable-resizable"
import "vue-draggable-resizable/dist/VueDraggableResizable.css"
import "bootstrap/dist/css/bootstrap.min.css"

import "./assets/icon/iconfont.css"
Vue.config.productionTip = false
// Vue.use(ElementUI, { locale, size: "small" })
// //全局注册svg-icon
Vue.component("svg-icon", IconSvg)
Vue.component("vue-draggable-resizable", VueDraggableResizable)

Vue.prototype._ = _
// 自定义指令
import "@/directive/el-select-loadmore/index"
import "@/directive/persstion-btn/index"
import "@/directive/resize/resize.js"
// 打印
// import Print from 'vue-print-nb'
// Vue.use(Print);
import Print from "@/plugs/print"
Vue.use(Print) // 注册
import md5 from "js-md5"
Vue.prototype.$md5 = md5

import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Descriptions,
  DescriptionsItem,
  Transfer,
  Container,
  Empty,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  Backtop,
  PageHeader,
  CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification,
  Badge
} from 'element-ui'

Vue.use(Badge)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Alert)
Vue.use(Slider)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Spinner)
Vue.use(Card)
Vue.use(Rate)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Cascader)
Vue.use(ColorPicker)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Transfer)
Vue.use(Container)
Vue.use(Empty)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Link)
Vue.use(Divider)
Vue.use(Image)
Vue.use(Calendar)
Vue.use(Backtop)
Vue.use(PageHeader)
Vue.use(CascaderPanel)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.prototype.$message = (msg) => {
  Message({ ...msg, duration: 500 })
};
[
  { name: "success", time: "800" },
  { name: "warning", time: "1000" },
  { name: "error", time: "1500" },
  { name: "info", time: "1500" }
].forEach((item) => {
  Vue.prototype.$message[item.name] = function(msg, time) {
    return Message[item.name]({
      message: msg,
      duration: time || item.time
    })
  }
})
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }
// AES
import AES from "./utils/AES"
Vue.prototype.$aes = AES

// NProgress.configure({
//   easing: "ease", // 动画方式
//   speed: 500, // 递增进度条的速度
//   showSpinner: false, // 是否显示加载ico
//   trickleSpeed: 200, // 自动递增间隔
//   minimum: 0.3, // 初始化时的最小百分比
// });
router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title + "-智慧运营"
  }
  // 每次切换页面时，调用进度条
  // NProgress.start();
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  // NProgress.done();
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
