import Cookies from "js-cookie"
import Vue from "vue"
import { getTree } from "@/api/projectDailyEneryTableManage"
import {
  sessionSet,
  sessionGet,
  sessionSetObj,
  sessionGetObj
} from "@/utils/auth"
// const departId = sessionGet('projectId')

const state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus")
      ? !!+Cookies.get("sidebarStatus")
      : true,
    withoutAnimation: false
  },
  isHigh: false,
  isPopup: false,
  no3D: false,
  loadingView: false,
  isDiagnosis: 0,
  keyArr: {}, // 左侧树结构缓存
  projectId: sessionGet("projectId"), // 项目id
  homeID: sessionGet("homeID"),
  RefreshInterface: sessionGet("RefreshInterface")
}

const mutations = {
  SET_REFRESHINTERFACE: (state, RefreshInterface) => {
    sessionSet("RefreshInterface", RefreshInterface)
    state.RefreshInterface = RefreshInterface
  },
  SET_HOMEID: (state, homeID) => {
    sessionSet("homeID", homeID)
    state.homeID = homeID
  },
  TOGGLE_PROJECTID: (state, projectId) => {
    sessionSet("projectId", projectId)
    state.projectId = projectId
  },

  SET_ISHIGH: (state, isHigh) => {
    state.isHigh = isHigh
  },
  SET_KEYARR: (state, keyArr) => {
    sessionSet("keyArr", keyArr)
    state.keyArr = keyArr
  },
  OPEN_NO3D: (state) => {
    state.no3D = true
  },
  CLOSE_NO3D: (state) => {
    state.no3D = false
  },
  CLOSE_ISPOPUP: (state) => {
    state.isPopup = true
  },
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1)
    } else {
      Cookies.set("sidebarStatus", 0)
    }
  },
  OPEN_SIDEBAR: (state) => {
    state.sidebar.opened = true
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set("sidebarStatus", 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },

  TOGGLE_LOAD: (state, loadingView) => {
    state.loadingView = loadingView
  },
  TOGGLE_DIAGNOSIS: (state, isDiagnosis) => {
    // sessionSet('isDiagnosis', isDiagnosis)
    state.isDiagnosis = isDiagnosis
  }
}

const actions = {
  setRefreshInterface({ commit }, setRefreshInterface) {
    commit("SET_REFRESHINTERFACE", setRefreshInterface)
  },
  setHomeID({ commit }, setHomeID) {
    commit("SET_HOMEID", setHomeID)
  },
  closeNo3d({ commit }) {
    commit("CLOSE_NO3D")
  },
  openNo3d({ commit }) {
    commit("OPEN_NO3D")
  },
  closeIsPopup({ commit }) {
    commit("CLOSE_ISPOPUP")
  },
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR")
  },
  openSlideBar({ commit }) {
    commit("OPEN_SIDEBAR")
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit("CLOSE_SIDEBAR", withoutAnimation)
  },
  toggleProjectId({ commit }, projectId) {
    commit("TOGGLE_PROJECTID", projectId)
  },
  setKeyArr: ({ commit }, KeyArr) => {
    commit("SET_KEYARR", KeyArr)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
