import request from "@/utils/request.js"
import Qs from "qs"
/**
 *
 * @param {设备设置新增和修改} params
 */
export function exportMethodAll(method, url, params, data, head) {
  return request({
    url: url,
    method: method,
    data: JSON.stringify(params),
    params: data,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json"
    }
  })
}
