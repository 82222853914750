import request from "@/utils/request"
/**
 *
 * @param {查询工艺} params
 */
export function getDeviceList(params) {
  return request({
    url: "system/deviceTreeByDepartmentId",
    method: "post",
    params
  })
}
/**
 *
 * @param {前台查询列表} params
 */
export function listHeatDailyFront(params) {
  return request({
    url: "/energymanage/gwph/listHeatDailyFront",
    method: "post",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
/**
 *
 * @param {查询列表} params
 */
export function listHeatDaily(params) {
  return request({
    url: "/energymanage/gwph/listHeatDaily",
    method: "post",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
/**
 *
 * @param {删除列表} params
 */
export function deleteHeatDaily(params) {
  return request({
    url: "energymanage/gwph/deleteHeatDaily",
    method: "post",
    params
  })
}
/**
 *
 * @param {查看详情} params
 */
export function selProjectHeatDaily(params) {
  return request({
    url: "energymanage/gwph/selProjectHeatDaily",
    method: "post",
    params
  })
}
/**
 *
 * @param {获取机构列表} params
 */
export function getTree(params) {
  return request({
    url: "/systemanage/systemDepartment/getTree",
    method: "post",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
/**
 *
 * @param {获取工艺列表} params
 */
export function productFacilityListTree(params) {
  return request({
    url: "/project/productFacility/productFacilityListTree",
    method: "post",
    params
  })
}
/**
 *
 * @param {*前台项目能耗日报--导出} params
 */
export function expListHeatDaily(params) {
  return request({
    url: "/energymanage/gwph/expListHeatDaily",
    method: "post",
    responseType: "blob",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
/**
 *
 * @param {下载} params
 */
export function downTemplate(params) {
  return request({
    url: "energymanage/EnergyControl/downTemplate",
    method: "post",
    responseType: "blob",
    data: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  })
}
