import Vue from "vue"
import store from "@/store/modules/user"
/**
 * 如何使用
 * <el-button type="primary" v-hasBtn="'add'">添加</el-button>
 * <el-button type="primary" v-hasBtn="'edit'">编辑</el-button>
 * <el-button type="primary" v-hasBtn="'delete'">删除</el-button>
 * <el-button type="primary" v-hasBtn="'select'">搜索</el-button>
 */

const hasBtn = Vue.directive("hasBtn", {
  bind: function(el, binding, vnode) {
    const btnPermissionsArr = vnode.context.$route.meta.btnPermissions
    const { value } = binding
    if (!Vue.prototype.$_hasBtn(btnPermissionsArr, value)) {
      const className = el.getAttribute("class")
      el.setAttribute("class", className)
      el.setAttribute("disabled", true)
      el.classList.add("is-disabled")
      // el.parentNode.removeChild(el)
    }
  }
})

Vue.prototype.$_hasBtn = function(value, name) {
  let isExist = false
  // 获取用户按钮权限
  const btnPermissionsStr = value
  if (btnPermissionsStr == undefined || btnPermissionsStr == null) {
    return false
  }

  if (value.includes(name)) {
    isExist = true
  }

  if (name == undefined || name == null || name == "default") {
    return true
  }
  return isExist
}

/**
 * 如何使用
 * <el-button type="primary" v-auth="'yjph'">添加</el-button>
 */
// 注册一个全局自定义指令 `v-auth`
const auth = Vue.directive("auth", {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: (el, binding, vnode) => {
    const value = binding.value
    if (value != undefined && value != "" && value != null) {
      var auths = store.state.btnPermission
      if (!auths.includes(value)) {
        el.parentNode.removeChild(el)
      }
    }
  }
})
export { hasBtn, auth }
