import store from "@/store"
const menu = store.getters.menu
// export var menuList = menu;
// menu.map(item=>{
//   item.children
// })
export var menuList = [
  {
    label: "公司总览",
    name: "Home",
    icon: "iconfont icon-icon_huabanfuben"
  },
  {
    label: " 项目概览 ",
    name: "projetHome",
    icon: "iconfont icon-icon_huabanfuben"
  },
  {
    label: " 驾驶舱 ",
    name: "cockpit",
    icon: "iconfont icon-jiashicang",
    child: [
      {
        label: " 系统监控图 ",
        name: "monitoring"
      },
      {
        label: " 驾驶模式 ",
        name: "DrivingModel"
      },
      {
        label: " 3D模型 ",
        name: "3Dmodel"
      },
      {
        label: " 决策机 ",
        name: "MasterDecMachine"
      }
    ]
  },
  {
    label: "视频安防", // 视频指导
    name: "VideoGuide",
    icon: "iconfont icon-shipin",
    child: [
      {
        label: "机房监控", // 监控设备状态
        name: "monitoringStatus"
      },
      {
        label: " 门禁管理 ",
        name: "accessControl"
      }
    ]
  },

  {
    label: "运行数据",
    name: "OperationData",
    icon: "iconfont icon-huanbao-environmentprotection",
    child: [
      {
        label: " 机房监测 ",
        name: "computerRoom"
      },
      {
        label: " 热网监测 ",
        name: "heatNetwork"
      },
      {
        label: " 户端监测 ",
        name: "userMonitoring"
      },
      {
        label: " 热水监测 ",
        name: "waterMonitoring"
      },
      {
        label: " 室温监测 ",
        name: "roomMonitoring"
      },
      {
        label: " 运行记录 ",
        name: "operationRecord"
      },
      {
        label: " 运行记录 ",
        name: "operationRecord1"
      },
      {
        label: " 数据查询 ",
        name: "dataQuery"
      }
    ]
  },
  {
    label: "能控大脑",
    name: "EnergyControl",
    icon: "iconfont icon-danao",
    child: [
      {
        label: " 能源统计 ",
        name: "energyStatistics"
      },
      {
        label: " 能源预测 ",
        name: "energyForecast"
      },
      {
        label: " 能源分析 ",
        name: "energyAnalysis"
      },
      {
        label: " 能源管控 ",
        name: "energyControls"
      },
      {
        label: "能耗日报 ",
        name: "DailyEneryTable"
      },
      {
        label: "能耗月报 ",
        name: "MonthlyEneryTable"
      }
    ]
  },
  {
    label: "能耗报表",
    name: "EnergyReport",
    icon: "iconfont iconbaobiao",
    child: [
      {
        label: " 能耗报表 ",
        name: "EnergyReports"
      }
    ]
  },
  {
    label: "安全管理",
    name: "SecurityManagement",
    icon: "iconfont icon-anquan",
    child: [
      {
        label: " 预警列表 ",
        name: "warningList"
      },
      {
        label: " 一键诊断 ",
        name: "oneDiagnosis"
      }
    ]
  },
  {
    label: "能效评价",
    name: "EnergyEfficiency",
    icon: "iconfont icon-gaiicon-",
    child: [
      {
        label: "管理能效 ",
        name: "ManagingEnergy"
      },
      {
        label: "系统能效",
        name: "SystemEnergy"
      }
    ]
  },
  {
    label: "看见节能",
    name: "EnergyConservation",
    icon: "iconfont icon-bulb-full",
    child: [
      {
        label: "管网平衡 ",
        name: "bipeBalance"
      },
      {
        label: "户阀节能",
        name: "householdControl"
      },
      {
        label: "烟热回收",
        name: "flueGasRecovery"
      },
      {
        label: "热泵节能",
        name: "pumpEnergySaving"
      },
      {
        label: "光伏发电",
        name: "photovoltaicPower"
      },
      {
        label: "户阀控制",
        name: "houseControl"
      },
      {
        label: "平衡调节",
        name: "balanceAdjustment"
      }
    ]
  },
  {
    label: "数据导出",
    name: "DataExport",
    icon: "iconfont icon-daochu",
    child: [
      {
        label: "导出模块",
        name: "exportAll"
      }
    ]
  },
  {
    label: "电子档案",
    name: "Archives",
    icon: "iconfont icon-danganguanli",
    child: [
      {
        label: " 项目基本情况 ",
        name: "projectStatus"
      },
      {
        label: " 工艺系统图 ",
        name: "technologyPic"
      },
      {
        label: " 外网系统图 ",
        name: "extranetPic"
      },
      {
        label: " 设备清单 ",
        name: "deviceList"
      },
      {
        label: "住户信息",
        name: "householdInfo"
      }
    ]
  },
  {
    label: "系统管理",
    name: "systemManage",
    icon: "iconfont icon-xitong-",
    child: [
      {
        label: "设备分组",
        name: "processGrouping"
      },
      {
        label: "设备管理",
        name: "equipmentManage",
        child: [
          {
            label: "工艺管理",
            name: "equipmentManage"
          },
          {
            label: "楼栋管理",
            name: "buildManage"
          },
          {
            label: "住户管理",
            name: "houseManage"
          }
        ]
      },
      {
        label: " 产品管理 ",
        name: "deviceTypeManage"
      },
      {
        label: " 实时数据管理 ",
        name: "realDataManage"
      },
      {
        label: " 离线数据管理 ",
        name: "offlineDataManage"
      },
      {
        label: "气象区域列表 ",
        name: "weatherManage"
      },
      // {
      //     label: ' 数据点配置管理 ',
      //     name: 'dataSetManage',
      // },
      // {
      //     label: ' 预警管理 ',
      //     name: 'warningManage',
      // },
      {
        label: " 首页排版",
        name: "pageSetManage"
      },
      {
        label: " 项目概况排版",
        name: "projetHomeSet"
      },
      {
        label: "能耗管理",
        name: "eneryManage",
        child: [
          {
            label: "能耗参数管理",
            name: "eneryParamManage"
          },
          {
            label: "能耗预测参数管理",
            name: "eneryForecastManage"
          },
          {
            label: "能耗表管理",
            name: "eneryTableManage"
          },
          // {
          //   label: "站点能耗表管理",
          //   name: "siteEneryTableManage",
          // },
          {
            label: "能耗日报管理",
            name: "dailyEneryTableManage"
          },
          {
            label: "项目能耗日报管理",
            name: "projectDailyEneryTableManage"
          }
        ]
      },
      {
        label: "节能产品管理",
        name: "energyEffic"
      },
      {
        label: "管网管理  ",
        name: "pipeNetworkMan",
        child: [
          {
            label: "管道区域管理  ",
            name: "pipeAreaMan"
          }
        ]
      },
      {
        label: "系统图管理",
        name: "configurationMan"
      },
      {
        label: " 项目管理 ",
        name: "projectManage"
      },
      {
        label: "小区管理",
        name: "cellManagement"
      },
      // {
      //   label: " 视频监控管理 ",
      //   name: "monitoringManage",
      // },
      {
        label: "视频安防管理",
        name: "VideoGuideManage",
        child: [
          {
            label: "机房监控管理",
            name: "monitoringManage"
          },
          {
            label: "门禁管理",
            name: "accessControlManage"
          }
        ]
      },
      {
        label: " 住户管理 ",
        name: "householdInfoManage"
      },
      {
        label: "组态元素管理 ",
        name: "conEleManage"
      },
      {
        label: "诊断管理",
        name: "diagnosisManage"
      },
      {
        label: "驾驶舱配置",
        name: "drivingModeConfig",
        child: [
          {
            label: "驾驶模式参数配置  ",
            name: "drivingModeManage"
          },
          {
            label: "气候补偿参数配置",
            name: "ClimateConfig"
          }
        ]
      },
      {
        label: "供暖季管理",
        name: "heatingSeason"
      },
      {
        label: "消息管理1111",
        name: "versionManagement"
      },
      {
        label: "工作协助",
        name: "workHelp"
      }
    ]
  }
]
export const FornMenuList = [
  {
    label: "项目基本情况详情查看",
    name: "projectDetail",
    location: "projectStatus"
  },
  {
    label: "设备清单详情查看",
    name: "deviceDetail",
    location: "deviceList"
  },
  {
    label: "水质记录",
    name: "WaterRecord",
    location: "operationRecord"
  },
  {
    label: "烟气排放",
    name: "fumeEmission",
    location: "operationRecord"
  },
  {
    label: " 监控设备实景 ",
    name: "monitoringView",
    location: "monitoringStatus"
  },
  {
    label: " 项目添加/修改 ",
    name: "projectAdd",
    location: "systemManage/projectManage"
  },
  {
    label: " 预警查看详情 ",
    name: "warningDetial",
    location: "systemManage/warningManage"
  },
  {
    label: " 首页排版详情 ",
    name: "pageSetDetial",
    location: "systemManage/pageSetManage"
  },
  {
    label: " 项目概览排版详情 ",
    name: "projetHomeSetDetial",
    location: "systemManage/projetHomeSet"
  }
]
