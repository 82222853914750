import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
const menu = store.getters.menu
import { menuList, FornMenuList } from "@/utils/pubMenu.js"
Vue.use(VueRouter)
const menuList3 = []

const routes = [
  {
    path: "/",
    name: "layout",
    meta: {
      title: "layout"
    },
    component: () => import("../layout"),
    children: [
      // {
      //   path: "/HomeProject",
      //   meta: {
      //     title: "项目概览",
      //   },
      //   name: "HomeProject",
      //   component: () => import("../views/projetHome"),
      // },
    ]
  },
  {
    path: "/MiddlePage",
    meta: {
      title: "中间页"
    },
    name: "MiddlePage",
    component: () => import("../views/MiddlePage/index.vue")
  },

  {
    path: "/HomePageName",
    meta: {
      title: "大屏首页"
    },
    name: "HomePageName",
    component: () => import("../views/Home/components/HomePage.vue")
  },
  {
    // label: '全屏系统图 ',
    // name: 'FullScreenPic',
    path: "/FullScreenPic",
    meta: {
      title: "全屏系统图"
    },
    name: "FullScreenPic",
    component: () => import("../views/FullScreenPic/index.vue")
  },
  {
    path: "/*",
    name: "404",
    meta: {
      title: "无法访问"
    },
    component: () => import("../views/404.vue")
  }
]
// routes[0].children.push({
//   path: "/home",
//   component: () => {
//     import("@/views/Home");
//   },
// });
menuList.forEach((item) => {
  if (item.child) {
    var url = ""
    if (item.name == "systemManage") {
      url = "/systemManage/"
    } else {
      url = "/"
    }
    item.child.forEach((child) => {
      if (child.child) {
        child.child.forEach((son) => {
          routes[0].children.push({
            path: "/" + son.name,
            name: son.name,
            meta: {
              title: son.label
            },
            component: () =>
              import("../views/systemManage/" + son.name + "/index.vue")
          })
        })
      } else {
        routes[0].children.push({
          path: "/" + child.name,
          name: child.name,
          meta: {
            title: child.label
          },
          component: () => import("../views" + url + child.name + "/index.vue")
        })
      }
    })
  } else {
    routes[0].children.push({
      path: "/" + item.name,
      name: item.name,
      meta: {
        title: item.label
      },
      component: () => import("../views/" + item.name + "/index.vue")
    })
  }
})
FornMenuList.forEach((item, index) => {
  var child = routes[0].children
  child.push({
    path: "/" + item.name,
    name: item.name,
    meta: {
      title: item.label
    },
    component: () =>
      import("../views/" + item.location + "/" + item.name + ".vue")
  })
})

const router = new VueRouter({
  mode: "history",
  routes
})
// router.addRoute("layout", {
//   path: "/home",
//   component: () => {
//     import("@/views/Home");
//   },
// });
// router.beforeEach((to, from, next) => {
//   let list = store.getters.menu.map((item) => {
//     return {
//       path: item.menuUrl,
//       name: item.menuUrl.substr(1),
//       meta: { title: item.menuName },
//       component: () => {
//         import("../views" + item.menuUrl);
//       },
//     };
//   });
//   if (list.length) {
//     console.log(list);
//     router.addRoute(...list);
//     next({ to, replace: true });
//     // next({ ...to, replace: true });
//   }
// });
// const originalPush = VueRouter.prototype.push;

// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => console.log(router));
// };
export default router
