import Vue from 'vue'
Vue.directive("drag", {
  inserted: function(el) {
    const dragBox = el // 获取当前元素
    dragBox.onmousedown = (e) => {
      // 算出鼠标相对元素的位置
      const disX = e.clientX - dragBox.offsetLeft
      const disY = e.clientY - dragBox.offsetTop
      // 为什么用document：如果绑定到元素本身的情况下，鼠标拖动过快，鼠标会离开拖拽的元素，导致拖拽一段距离，拖拽失效的问题
      document.onmousemove = (e) => {
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        const left = e.clientX - disX
        const top = e.clientY - disY
        const FWidth = el.parentNode.offsetWidth - el.offsetWidth
        const FHeight = el.parentNode.offsetHeight - el.offsetHeight
        // 移动当前元素
        // console.log('left' + left);
        // console.log('top' + top);
        // 判断当前元素可以活动的区域
        if (left <= 0) {
          dragBox.style.left = 0 + 'px'
        } else if (left >= FWidth) {
          dragBox.style.left = FWidth + 'px'
        } else if (left > 0) {
          dragBox.style.left = left + 'px'
        }
        if (top <= 0) {
          dragBox.style.top = 0 + 'px'
        } else if (top >= FHeight) {
          dragBox.style.top = FHeight + 'px'
        } else if (top > 0) {
          dragBox.style.top = top + 'px'
        }
      }
      document.onmouseup = (e) => {
        // 鼠标弹起来的时候不再移动
        document.onmousemove = null
        // 预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
        document.onmouseup = null
      }
    }
  }
})
