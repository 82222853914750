import elementResize from "element-resize-detector" // 尺寸监听组件
import * as echarts from "echarts"
import Vue from 'vue'
Vue.directive('chart-resize', {
  bind(el, binding) {
    console.log(el)
    console.log(binding)
    const SELECTWRAP_DOM = el.querySelector('.chart_resize')
    var elementResize2 = elementResize({
      strategy: "scroll", // <- 推荐监听滚动，提升性能
      callOnAdd: true // 添加侦听器时是否应调用,默认true
    })
    elementResize2.listenTo(SELECTWRAP_DOM, function(element) {
      echarts.init(SELECTWRAP_DOM).resize() // 当元素尺寸发生改变是会触发此事件，刷新图表
    })
  }
})
