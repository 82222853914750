<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
    <el-dialog
      title="版本更新"
      :visible.sync="isUpdatedVersion"
      width="20%"
      center
      custom-class="connectIphone"
    >
      <div class="connectOur">
        <div class="serve">系统检测到有新版本，是否立即更新？</div>
        <div class="tip">温馨提示：点击取消之后,可稍后手动刷新更新版本。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="confirmUpdatedVersion"
        >确 定</el-button>
        <el-button @click="isUpdatedVersion = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="updatePsd"
      width="20%"
      center
      custom-class="connectIphone"
    >
      <div class="connectOur">
        <div class="serve">您的密码安全等级较低，是否立即修改密码？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmUpdatedPsd">确 定</el-button>
        <el-button @click="updatePsd = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import DevicePixelRatio from "@/utils/devicePixelRatio.js"
import VueRouter from "@/router/index"
import store from "@/store"
import { mapGetters } from "vuex"
import axios from "axios"
import { envUrl } from "@/utils"
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapGetters(["projectId", "password"])
  },
  data() {
    return {
      isRouterAlive: true,
      isUpdatedVersion: false,
      updatePsd: false
    }
  },
  created() {
    if (
      window.location.pathname === "/HomePageName" ||
      window.location.pathname === "/HomePageName/" ||
      window.location.pathname === "/FullScreenPic"
    ) {
      return
    } else {
      this.checkUpdatePsd()
    }
    // console.log(window.name)
    // setInterval(() => {
    //   this.checkVersion();
    // }, 120000); // 2分钟检查一次
    // if (window.name == "") {
    //   if (
    //     sessionStorage.getItem("pathName") == "null" ||
    //     sessionStorage.getItem("pathName") == "MiddlePage" ||
    //     sessionStorage.getItem("pathName") == "HomePageName"
    //   ) {
    //     window.name = "Home";
    //   } else {
    //     window.name = sessionStorage.getItem("pathName");
    //   }
    // }
    // var pId = this.projectId;
    // 页面刷新时将数据存储到sessionStorage中
    // window.addEventListener("load", () => {
    //   // if (sessionStorage.getItem('pathName') ) {
    //   //   this.$store.replaceState( Object.assign( {}, this.$store.pathName,
    //   //   JSON.parse(sessionStorage.getItem('pathName') ) ) )
    //   // }
    //   sessionStorage.setItem("pathName", window.name);
    //   //   this.$router.replace(sessionStorage.getItem('pathName'))
    //   //   this.$router.push({
    //   //     name: window.name
    //   // })
    //   // this.$store.commit("app/TOGGLE_PROJECTID", pId);
    // });
  },
  mounted() {
    // this.fitScreen();
    // let sizeFun = () => {
    //   this.fitScreen();
    // };
    // window.addEventListener("resize", sizeFunKW);
    // console.log(new DevicePixelRatio())
    // new DevicePixelRatio().init();
    // bodyScale ()
    // const bodyScale=() => {

    //   var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
    //   var scale = devicewidth / 1920; // 分母——设计稿的尺寸
    //   document.body.style.zoom = scale; //放大缩小相应倍数
    // };
  },

  methods: {
    checkUpdatePsd() {
      // let str= /^(?=.*\d)[^]/;
      // /^\S*(?=\S{8,16})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!~@#$%^&*?_-])\S*$/;
      setTimeout(() => {
        if (Number(this.password)) {
          this.updatePsd = true
        } else {
          this.updatePsd = false
        }
      }, 1000)
    },
    checkVersion() {
      axios
        .get(`/js/version.json`, {
          headers: { "Cache-Control": "no-cache" }
        }) // 反正就是要请求到json文件的内容, 并且禁止缓存
        .then((res) => {
          this.currentVersion = res.data.version
          const clientVersion = localStorage.getItem("_version_")
          // 和最新版本不同，刷新页面
          if (this.currentVersion !== clientVersion) {
            this.isUpdatedVersion = true
          }
        })
    },
    confirmUpdatedVersion() {
      localStorage.setItem("_version_", this.currentVersion)
      window.location.reload()
    },
    confirmUpdatedPsd() {
      window.top.location.href = envUrl + "/ChangePsd?isUpdate = 1"
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    // 屏幕自适应
    fitScreen() {
      const htmlWidth =
        document.documentElement.clientWidth || document.body.clientWidth
      const htmlDom = document.getElementsByTagName("html")[0]
      // htmlDom.style.fontSize = 16 * (htmlWidth / 1480) + "px";
      htmlDom.style.fontSize = htmlWidth / 10 + "px"
    },
    isBlank(data) {
      if (
        data == null ||
        data === "null" ||
        data === "" ||
        data === undefined ||
        data === "undefined" ||
        data === "unknown"
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" >
body .el-table th.gutter {
display: table-cell !important
}
.connectOur {
  margin-bottom: 10px;
  .tip {
    margin: 30px auto;
    text-align: center;
    color: #999;
    height: 30px;
    line-height: 50px;
    border-top: 1px solid #999;
    margin-bottom: -15px;
  }
}
</style>
