var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.isRouterAlive ? _c("router-view") : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "版本更新",
            visible: _vm.isUpdatedVersion,
            width: "20%",
            center: "",
            "custom-class": "connectIphone",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isUpdatedVersion = $event
            },
          },
        },
        [
          _c("div", { staticClass: "connectOur" }, [
            _c("div", { staticClass: "serve" }, [
              _vm._v("系统检测到有新版本，是否立即更新？"),
            ]),
            _c("div", { staticClass: "tip" }, [
              _vm._v("温馨提示：点击取消之后,可稍后手动刷新更新版本。"),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmUpdatedVersion },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isUpdatedVersion = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.updatePsd,
            width: "20%",
            center: "",
            "custom-class": "connectIphone",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updatePsd = $event
            },
          },
        },
        [
          _c("div", { staticClass: "connectOur" }, [
            _c("div", { staticClass: "serve" }, [
              _vm._v("您的密码安全等级较低，是否立即修改密码？"),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmUpdatedPsd },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.updatePsd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }